import { parse } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { DataSulStatus } from '../common/enum/dataSulStatus';
import { audienceDictionary, formatString } from '../constants/class';
import { getSouthDate } from '../services/courses';
import { convertStringToCurrency, dealWithTitleChange } from '../utils';

export default function useGetClass(
  methods: UseFormReturn<any, any>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const handleInvalidCodeError = () => {
    toast.error('O Código Da Metodologia deve ser número.');
  };

  const getClass = async () => {
    const { getValues, setValue, setError, clearErrors } = methods;
    const classCode = getValues('sebrae_code');
    const invalidCodeRegex = /[^0-9]/g;

    if (invalidCodeRegex.test(classCode)) {
      handleInvalidCodeError();
      return;
    }

    try {
      setIsLoading(true);

      const resp = await getSouthDate(classCode);

      if (resp['message']) {
        throw new Error(resp['message']);
      }

      const instructorSlug = dealWithTitleChange(resp.instructor);
      const meetings = resp.schedules.map(item => ({
        start_at: parse(item.start, formatString, new Date()),
        finish_at: parse(item.finish, formatString, new Date()),
        sebrae_id: item.id.toString(),
      }));

      if (resp.message) {
        setError('sebrae_code', { message: resp.message });
        toast({
          message: resp.message,
          type: 'warning',
        });
        return;
      }

      clearErrors('sebrae_code');
      console.log(audienceDictionary[resp?.tipo_publico?.toLowerCase()]);
      setValue(
        'audience',
        audienceDictionary[resp?.tipo_publico?.toLowerCase()],
      );
      setValue('description', resp.description);
      setValue('duration', resp.duration);
      setValue('finish_at', parse(resp.finish, formatString, new Date()));
      setValue('instructor_cpf', resp.cpf_instructor || '');
      setValue('instructor_name', resp.instructor);
      setValue('instructor_email', resp.email_instructor);
      setValue('instructor_is_email_cpf_blocked', true);
      setValue('instructor_slug', instructorSlug);
      setValue('meetings', meetings);
      setValue('moderators', []);
      setValue('name', resp.name);
      setValue('price', convertStringToCurrency(resp.price.toString()));
      setValue('start_at', parse(resp.start, formatString, new Date()));
      setValue('status', DataSulStatus[resp.status.toUpperCase()]);
      setValue('sebrae_code_content', resp.methodology.code);
      setValue('vacancies', resp.vacancies);
      setValue('current_vacancies', resp.vacancies);

      toast({
        message: 'Informações da turma carregadas com sucesso!',
        type: 'success',
      });
    } catch (error: any) {
      console.error(error);
      setError('sebrae_code', {
        message: 'Código da turma com erro ou inválido',
      });
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar as informações da turma!',
        text: error?.response?.data?.message || error.message,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { getClass };
}
