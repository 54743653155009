import { zodResolver } from '@hookform/resolvers/zod';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { syncClassSchema } from '../../../../common/validations/syncClassValidation';
import { convertTimeToMilliseconds } from '../../../../helpers/convertTimeToMilliseconds';
import useGetClass from '../../../../hooks/useGetClass';
import { ICreateClass } from '../../../../models/SyncCourse';
import { createSyncClass } from '../../../../services/syncCourse';
import ClassTemplate from '../../../../templates/ClassTemplate';
import { convertStringToNumber, removeTraitPoints } from '../../../../utils';

export type SyncClassFormValues = z.infer<typeof syncClassSchema>;

export default function SyncClassCreate() {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const methods = useForm<SyncClassFormValues>({
    resolver: zodResolver(syncClassSchema),
    defaultValues: {
      instructor_is_email_cpf_blocked: true,
    },
  });
  const { getClass } = useGetClass(methods, setIsLoading);

  const handleSubmit = async (data: SyncClassFormValues) => {
    const obj = {} as ICreateClass;

    delete data['moderators_selected'];
    delete data['users_selected'];
    delete data['current_vacancies'];
    Object.assign(obj, data);

    obj['price'] = convertStringToNumber(data['price']);
    obj['vacancies'] = Number(data['vacancies']);
    obj['duration'] = convertTimeToMilliseconds(data['duration']);
    obj['instructor_cpf'] = removeTraitPoints(data['instructor_cpf'])!;

    createSyncClass(obj)
      .then(() => {
        Swal.fire({
          title: 'Turma criada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao criar a turma',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  const verifyVisibility = useMemo(() => {
    const {
      formState: { errors },
    } = methods;
    if (errors.sebrae_code) {
      return true;
    } else {
      return false;
    }
  }, [methods.formState]);

  return (
    <FormProvider {...methods}>
      <ClassTemplate
        title="Cadastro de turma"
        getClass={getClass}
        isLoading={isLoading}
        submit={handleSubmit}
        visibility={verifyVisibility}
      />
    </FormProvider>
  );
}
