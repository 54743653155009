export const audienceDictionary = {
  aberto: 'open',
  fechado: 'closed',
  definido: 'exclusive',
};

export const statusDictionary = {
  active: 'Ativo',
  inactive: 'Inativo',
};

export const formatString = 'dd/MM/yyyy HH:mm';
