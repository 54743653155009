import { SearchOutlined } from '@mui/icons-material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useGetContent, useTablePagination } from '../../../hooks';
import { IMinimalUser } from '../../../models/user';
import { listUsers } from '../../../services/users';
import { formatCnpj, formatCpf, removeTraitPoints } from '../../../utils';
import { handleSort } from '../../../utils/handleSort';
import Button from '../../Button';
import CustomSelect from '../../CustomSelect';
import { FlexContainer } from '../../FlexContainer';
import Table, { TableProps } from '../../Table';
import SimpleModal from '..';
import RegisterUserModal from '../RegisterUserModal';
import * as S from './styles';

type UserModalProps = {
  isOpen: boolean;
  classUser: string[] | [];
  visibility?: boolean;
  idOfUsersWhoPurchased: string[];
  onCloseChange: () => void;
  handleSelected: (users) => void;
  handleDeselect: (users) => void;
};

type OrderBySortType = 'ASC' | 'DESC';
type OrderByType = 'name' | 'created_at' | 'last_login';
type RowType = {
  user_id: string;
  name: string;
  cpf: string;
  email: string;
  is_admin: boolean;
  author_id: string | null;
  moderator_id: string | null;
  created_at: Date;
  companies: [];
};
type ParamsType = {
  limit: number;
  offset: number;
  order_by?: OrderByType;
  order_by_sort: OrderBySortType;
};

const optionsToSelect = [
  { label: 'Nome', value: 'search' },
  { label: 'CPF', value: 'cpf' },
  { label: 'CNPJ', value: 'cnpj' },
  { label: 'Nome da empresa', value: 'search_company' },
];

export default function UserModal({
  classUser,
  isOpen,
  visibility,
  idOfUsersWhoPurchased,
  handleSelected,
  handleDeselect,
  onCloseChange,
}: UserModalProps) {
  const [rows, setRows] = useState<IMinimalUser[] | []>([]);
  const [orderBySort, setOrderBySort] = useState<OrderBySortType>('ASC');
  const [orderBy, setOrderBy] = useState<OrderByType>('name');
  const [selectedFilter, setSelectedFilter] = useState<{
    label: string;
    value: string;
  }>(optionsToSelect[0]);
  const [openModal, setOpenModal] = useState(false);
  const [haveNewUser, setHaveNewUser] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { loading, getVariedContents } = useGetContent(listUsers);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const columns = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 100,
        renderCell: ({ row }: { row: RowType }) => (
          <S.Input
            type="checkbox"
            checked={classUser.some(userId => userId === row.user_id)}
            onChange={e => {
              e.target.checked
                ? handleSelectUser(row.user_id)
                : handleDeselect(row.user_id);
            }}
            disabled={
              idOfUsersWhoPurchased.some(userId => userId === row.user_id) &&
              visibility
            }
          />
        ),
      },
      {
        headerName: 'Nome',
        field: 'name',
        minWidth: 200,
        flex: 2,
        valueGetter: ({ row }) => row.name,
      },
      {
        headerName: 'CPF',
        field: 'cpf',
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => formatCpf(row.cpf),
      },
      {
        headerName: 'E-mail',
        field: 'email',
        minWidth: 200,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row.email,
      },
    ],
    [classUser, rows],
  );

  const params: ParamsType = {
    limit: paginationModel.pageSize,
    offset: paginationModel.page,
    order_by: orderBy,
    order_by_sort: orderBySort,
  };

  useEffect(() => {
    getVariedContents(setRows, setRowCount, params);

    if (haveNewUser) setHaveNewUser(false);
  }, [
    paginationModel.pageSize,
    paginationModel.page,
    orderBy,
    orderBySort,
    haveNewUser,
  ]);

  const handleSelectUser = (userId: string) => {
    const user = rows.filter(row => row.user_id === userId);
    handleSelected(user);
  };

  const onPageChange = (page: number) => {
    changePage(page);
  };

  const onPageSizeChange = (pageSize: number) => {
    changePageSize(pageSize);
  };

  const tableProps: TableProps = {
    columns,
    rows,
    keyId: 'user_id',
    onPageChange,
    onPageSizeChange,
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    selectionModel: classUser,
    sortingMode: 'server',
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
        letterConverter: 'toLowerCase',
      }),
    disableColumnMenu: true,
    initialState: {
      sorting: {
        sortModel: [{ field: 'name', sort: 'asc' }],
      },
    },
  };

  const applyMask = value => {
    if (selectedFilter.value === 'cpf') {
      return formatCpf(value);
    } else if (selectedFilter.value === 'cnpj') {
      return formatCnpj(value);
    } else {
      return value;
    }
  };

  const handleMaskInput = () => {
    if (!inputRef.current) return;

    const inputValue = inputRef.current.value;
    inputRef.current.value = applyMask(inputValue);
  };

  const handleSubmit = () => {
    const searchInput = inputRef.current?.value;
    const searchParams = params;

    if (searchInput !== undefined && searchInput.length) {
      const filter = selectedFilter?.value;
      searchParams[filter] = handleMask[filter](searchInput);
    }

    getVariedContents(setRows, setRowCount, searchParams);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  function CustomToolbar() {
    return (
      <S.ToolBarWrapper>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Criar usuário
        </Button>
      </S.ToolBarWrapper>
    );
  }

  const components = {
    Toolbar: CustomToolbar,
  };

  const handleMask = {
    cpf: (cpf: string) => removeTraitPoints(cpf),
    search: (name: string) => name,
    cnpj: (cnpj: string) => removeTraitPoints(cnpj),
    search_company: (searchCompany: string) => searchCompany,
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Lista de Alunos"
    >
      <S.Wrapper>
        <FlexContainer gap="0.875rem">
          <CustomSelect
            id="filter"
            isRtl={false}
            isSearchable
            options={optionsToSelect}
            onChange={value => {
              if (inputRef.current) inputRef.current.value = '';
              if (value) setSelectedFilter(value);
            }}
            value={selectedFilter}
            thereIsError={false}
            marginTop="0.875rem"
          />

          <S.Input
            id="search"
            ref={inputRef}
            placeholder={`Busque pelo ${selectedFilter?.label.toLocaleLowerCase()}`}
            onChange={handleMaskInput}
            onKeyDown={handlePressEnter}
          />

          <S.SearchButton
            type="button"
            title="Fazer a busca pelo nome do usuário"
            onClick={handleSubmit}
          >
            <SearchOutlined fontSize="inherit" />
          </S.SearchButton>
        </FlexContainer>

        <Table
          {...tableProps}
          components={components}
          width="90vw"
          height="100%"
          checkboxSelection
          disableColumnMenu
        />
      </S.Wrapper>

      {openModal &&
        createPortal(
          <RegisterUserModal
            isOpen={openModal}
            onCloseChange={() => setOpenModal(false)}
            resgisteredNewUser={() => setHaveNewUser(true)}
            handleUserSelected={handleSelected}
          />,
          document.body,
        )}
    </SimpleModal>
  );
}
