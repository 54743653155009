import { ScatterDataPoint } from 'chart.js';
import { CoreChartOptions } from 'chart.js';
import { PluginChartOptions } from 'chart.js';
import { DatasetChartOptions } from 'chart.js';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ElementChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineControllerChartOptions,
  LineElement,
  PointElement,
  ScaleChartOptions,
  Title,
  Tooltip,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { FlexContainer } from '../../components/FlexContainer';
import Heading from '../../components/Heading';
import { ICountConsumption } from '../../models/from-api-response/report';
import theme from '../../styles/theme';
import { hexTransparentize } from '../../utils';
import * as S from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

type LineOptions = _DeepPartialObject<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
>;

type ConsumptionWithLabel = {
  label: string;
} & ICountConsumption;

type DashboardTemplateProps = {
  listConsumption: ICountConsumption[];
};

export default function DashboardTemplate({
  listConsumption,
}: DashboardTemplateProps) {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const addMonthLabel = (consumption: ICountConsumption) => ({
    ...consumption,
    label: monthNames[consumption.month - 1],
  });

  const orderConsumption = (
    a: ConsumptionWithLabel,
    b: ConsumptionWithLabel,
  ) => {
    if (a.year !== b.year) return a.year - b.year;

    return monthNames.indexOf(a.label) - monthNames.indexOf(b.label);
  };

  const consumptionCount = (consumption: ICountConsumption) =>
    consumption.count;

  const consumptionCountValid = (consumption: ICountConsumption) =>
    consumption.count_valid;

  const consumptionLabels = (consumption: ConsumptionWithLabel) =>
    `${consumption.label.slice(0, 3)}-${consumption.year.toString().slice(-2)}`;

  const options: LineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      datalabels: {
        display: false,
      },
    },
  };

  const data: ChartData<'line', (number | ScatterDataPoint | null)[], string> =
    {
      labels: listConsumption
        .map(addMonthLabel)
        .sort(orderConsumption)
        .map(consumptionLabels),
      datasets: [
        {
          label: 'Consumo do mês',
          data: listConsumption
            .map(addMonthLabel)
            .sort(orderConsumption)
            .map(consumptionCount),
          borderColor: theme.colors.chart_blue,
          backgroundColor: hexTransparentize(theme.colors.chart_blue),
          pointStyle: 'circle',
          pointRadius: 8,
        },
        {
          label: 'Consumo válido do mês',
          data: listConsumption
            .map(addMonthLabel)
            .sort(orderConsumption)
            .map(consumptionCountValid),
          borderColor: theme.colors.chart_green,
          backgroundColor: hexTransparentize(theme.colors.chart_green),
          pointStyle: 'circle',
          pointRadius: 8,
        },
      ],
    };

  return (
    <FlexContainer
      width="100%"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <S.Card style={{ marginBottom: '1rem', width: '85%' }}>
        <S.CardHeader>
          <Heading
            level={2}
            size="medium"
            color="neutral_900"
            lineHeight={'1.5rem'}
          >
            Lista de consumo dos últimos 12 meses
          </Heading>
        </S.CardHeader>

        <S.CardBody>
          <Line options={options} data={data} />
        </S.CardBody>
      </S.Card>
    </FlexContainer>
  );
}
